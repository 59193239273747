<template>
  <div
    data-cy="cmp-avatar"
    :class="rootClass"
    :style="bgImage"
  >
    <span
      v-if="text && !image"
      class="text"
    >{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    image: { type: String, default: null },
    size: { type: String, default: 'default' },
    text: { type: String, default: null },
    seed: { type: String, default: null },
  },
  computed: {
    rootClass() {
      return {
        avatar: true,
        'avatar--large': this.size === 'large',
        'avatar--medium': this.size === 'medium',
        'avatar--small': this.size === 'small',
      };
    },
    bgImage() {
      return this.image ? `background-image: url('${this.image}')` : this.bgGradient;
    },
    bgGradient() {
      return {
        background: `linear-gradient(135deg,hsla(${this.hue}, 42%, 56%, 1) 0%, hsla(${this.hue}, 39%, 51%, 1) 100%)`,
      };
    },
    hue() {
      let charVal = 0;
      const seed = this.seed || this.text;
      for (let i = 0; i < seed.length; i += 1) {
        charVal += seed.charCodeAt(i) * 1000;
      }
      return charVal % 360;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/main";

$large: null;
$medium: null;
$small: null;

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  &--large {
    $large: & !global;

    width: 88px;
    height: 88px;
  }

  &--medium {
    $medium: & !global;

    width: 60px;
    height: 60px;
  }

  &--small {
    $small: & !global;

    width: 24px;
    height: 24px;

    border-radius: 8px;
  }
}

.text {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  word-break: break-all;
  overflow: hidden;
  height: 13px;
  line-height: 13px;
  max-width: 80%;
  text-align: center;
  text-transform: uppercase;

  #{$large} & {
    font-weight: 400;
    font-size: 22px;
    height: 22px;
    line-height: 22px;
  }
}
</style>
